// 외부 라이브러리 스타일
@use 'variable' as *;
// SwiperJs
@import 'swiper/css/bundle';

.swiper-pagination-bullet {
  background-color: #fff;
  opacity: 0.5;
  border-radius: 50%;
  margin: 0 4px;
}

.swiper-pagination-bullet-active {
  background-color: $primary-color;
  opacity: 1;
}
