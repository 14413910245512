@forward 'at-rules';
@use 'at-rules' as *;

// html 기본 폰트 크기
$html-font-size: 16px;

// 미디어쿼리 모바일 max-width
$max-width-s: 599px;
// 미디어쿼리 태블릿 max-width
$max-width-m: 839px;
// 미디어쿼리 태블릿 min-width
$min-width-m: 600px;
// 미디어쿼리 데스크톱 min-width
$min-width-l: 840px;
// 컨텐츠 영역 최대 너비
$content-max-width: 1200px;

$tournity-color-blue: #145a9f;

// $theme-primary-500: #5d8eeb;
// $theme-secondary-500: #ffa017;
// $theme-warn-500: #ff0000;

$safe-area-top: var(--pw-safe-area-top, 0px);
$safe-area-bottom: var(--pw-safe-area-bottom, 0px);
$safe-area-left: var(--pw-safe-area-left, 0px);
$safe-area-right: var(--pw-safe-area-right, 0px);

// TODO: CSS 변수화

// 주 색상
$primary-color: var(--primary-color-500) !default;
// 주 텍스트 색상
$on-primary-color: var(--on-primary-color-500);

// 보조 색상
$secondary-color: var(--secondary-color-500) !default;
// 보조 텍스트 색상
$on-secondary-color: var(--on-secondary-color-500);

// 제3 색상
$tertiary-color: var(--tertiary-color-500) !default;
// 제3 텍스트 색상
$on-tertiary-color: var(--on-tertiary-color-500);

@mixin primary-theme {
  @include color-theme($primary-color, $on-primary-color);
}

@mixin secondary-theme {
  @include color-theme($secondary-color, $on-secondary-color);
}

@mixin tertiary-theme {
  @include color-theme($tertiary-color, $on-tertiary-color);
}

// 경고 색상
$warn-color: $secondary-color !default;

// 주제 색
$title-color: #000;
// 부제 및 본문 색 1
$content-color-1: #2a2a2a;
// 부제 및 본문 색 2
$content-color-2: #646464;
// 테두리 색 1
$border-color-1: #999;
// 테두리 색 2
$border-color-2: #f9f9f9;
// 테두리 색 3
$border-color-3: #aaa;
// 테두리 색 4
$border-color-4: #ebebeb;
// 비활성 요소 폰트 색
$disabled-color: $border-color-1;
// 비활성 요소 배경 색
$disabled-background-color: $border-color-2;

// 버튼, 인풋 등 모서리
$border-radius: 10px !default;

// 모바일 헤더 높이
$header-height-mobile: 50px !default; // 56px
// 데스크톱 헤더 높이
$header-height-desktop: 80px !default;
$header-menu-height-desktop: 56px !default;
// 모바일 푸터 높이
$footer-height-mobile: 0px !default;
// 데스크톱 푸터 높이
$footer-height-desktop: 418px !default;
